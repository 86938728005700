import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'

import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'

import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const KolayErisimGercek = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Ambalaj Modellerinde Hızlı Arama Kolay Erişim ' />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Kolay Erişim</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Mobil ve web ortak platformda kullanıcı dostu ekranlarla tasarlanmış uygulamalarımız firmanızın vazgeçilmezi haline dönüşecektir.</div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageKolayErisimGercekContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default KolayErisimGercek
